import React, { ComponentProps } from "react";

import Image from "next/image";

import Loader from "../../assets-protocol-v4/images/loader.svg";

interface SecondaryButtonProps extends ComponentProps<"button"> {
  variant: "dark" | "light";
  loading?: boolean;
}

const getVariantClassNames = (variant: SecondaryButtonProps["variant"]) => {
  switch (variant) {
    case "dark":
      return "bg-black text-white hover:bg-[#44444B]";
    case "light":
      return "bg-white text-black hover:bg-[#44444B] hover:text-white";
  }
};

const getInsetFrameClassNames = (
  variant: SecondaryButtonProps["variant"],
  position: ["top" | "bottom", "left" | "right"]
) => {
  let classes = "";
  switch (variant) {
    case "dark":
      classes += "border-white";
      break;
    case "light":
      classes +=
        "border-black group-hover:border-white group-focus:border-white group-focus-within:border-white";
      break;
  }

  switch (position[0]) {
    case "top":
      classes +=
        " top-0.5 border-t-2 group-hover:border-t-3 group-hover:top-1 group-focus:border-t-3 group-focus-within:border-t-3 group-focus:top-1 group-focus-within:top-1";
      break;
    case "bottom":
      classes +=
        " bottom-0.5 border-b-2 group-hover:border-b-3 group-focus:border-b-3 group-focus-within:border-b-3 group-hover:bottom-1 group-focus:bottom-1 group-focus-within:bottom-1";
      break;
  }

  switch (position[1]) {
    case "left":
      classes +=
        " left-0.5 border-l-2 group-hover:border-l-3 group-focus:border-l-3 group-focus-within:border-l-3 group-hover:left-1 group-focus:left-1 group-focus-within:left-1";
      break;
    case "right":
      classes +=
        " right-0.5 border-r-2 group-hover:border-r-3 group-focus:border-r-3 group-focus-within:border-r-3 group-hover:right-1 group-focus:right-1 group-focus-within:right-1";
      break;
  }

  return classes;
};

const SecondaryButton = ({
  variant,
  children,
  className,
  loading,
  ...props
}: SecondaryButtonProps) => {
  return (
    <button
      className={`disabled:opacity-50 disabled:cursor-not-allowed relative w-full h-full font-jetbrains overflow-hidden group duration-150 flex justify-center items-center gap-2 text-sm ${getVariantClassNames(
        variant
      )} ${className}`}
      {...props}
    >
      <span
        className={`inline-block duration-300 ease-out w-2.5 h-2.5 absolute ${getInsetFrameClassNames(
          variant,
          ["top", "left"]
        )}`}
      />
      <span
        className={`inline-block duration-300 ease-out w-2.5 h-2.5 absolute ${getInsetFrameClassNames(
          variant,
          ["top", "right"]
        )}`}
      />
      <span
        className={`inline-block duration-300 ease-out w-2.5 h-2.5 absolute ${getInsetFrameClassNames(
          variant,
          ["bottom", "left"]
        )}`}
      />
      <span
        className={`inline-block duration-300 ease-out w-2.5 h-2.5 absolute ${getInsetFrameClassNames(
          variant,
          ["bottom", "right"]
        )}`}
      />
      {loading && (
        <Image src={Loader} alt="" className="animate-spin w-5 h-5" />
      )}
      {children}
    </button>
  );
};

export default SecondaryButton;
