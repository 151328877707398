import React, { ReactNode, useEffect, useRef, useState } from "react";

import Image from "next/image";

import ArrowDownIcon from "../../assets-protocol-v4/image-component/arrow-down";
import ArrowDown from "../../assets-protocol-v4/images/arrow-down.svg";
import SpheronLogo from "../../assets-protocol-v4/spheron-logo.svg";
import { trackUserEvent } from "../../utils/analytics";
import { RedirectLinks } from "../../utils/common";
import Dot from "../Dot";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton/SecondaryButton";

export enum NavbarItems {
  HOME = "Home",
  SOLUTIONS = "Solutions",
  RESOURCES = "Resources",
  COMPANY = "Company",
  SLARK_NODE = "Slark Node",
}

interface NavBarProps {
  page: NavbarItems;
  buttonContent: ReactNode;
  buttonOnClick: () => void;
}

const NavBar = ({ page, buttonContent, buttonOnClick }: NavBarProps) => {
  const navItems: {
    title: NavbarItems;
    handleClick: () => void;
    dropdownHeight?: number;
    dropwdownItem: {
      title: string;
      rightIcon?: JSX.Element;
      hasOnClick?: boolean;
      handleClick: () => void;
    }[];
  }[] = [
    {
      title: NavbarItems.HOME,
      handleClick: () => {
        window.open("/", "_self");
        setSelectedNavItem(NavbarItems.HOME);
        setIsOpen(false);
      },
      dropwdownItem: [],
    },
    {
      title: NavbarItems.SOLUTIONS,
      handleClick: () => {
        setSelectedNavItem(NavbarItems.SOLUTIONS);
      },
      dropdownHeight: 184,
      dropwdownItem: [
        {
          title: "Provider",
          handleClick: () => {
            window.open(RedirectLinks.PROVIDER_APP);
          },
        },
        {
          title: "Fizz Node",
          handleClick: () => {
            window.open("/fizz");
          },
        },
        {
          title: "Console",
          handleClick: () => {
            window.open(RedirectLinks.CONSOLE_APP);
          },
        },
        {
          title: "CLI",
          handleClick: () => {
            window.open(RedirectLinks.CLI_DOCS);
          },
        },
        {
          title: "Cloud",
          handleClick: () => {},
          rightIcon: (
            <div
              className="text-protocolNew-base text-[10px] px-[5px] py-0.5 rounded-md bg-protocolNew-badge-bg
            leading-4 font-roboto -mr-[82px]"
            >
              Coming soon!
            </div>
          ),
        },
        // {
        //   title: "Slark Node",
        //   hasOnClick: false,
        //   rightIcon: (
        //     <div
        //       className="text-protocolNew-base text-[10px] px-[5px] py-0.5 rounded-md bg-protocolNew-badge-bg
        //     leading-4 font-roboto -mr-[82px]"
        //     >
        //       Coming soon!
        //     </div>
        //   ),
        //   handleClick: () => {
        //     // window.open(RedirectLinks.MARKETPLACE_GUIDE);
        //   },
        // },
      ],
    },
    {
      title: NavbarItems.RESOURCES,
      handleClick: () => {
        setSelectedNavItem(NavbarItems.RESOURCES);
      },
      dropdownHeight: 144,
      dropwdownItem: [
        {
          title: "Docs",
          handleClick: () => {
            window.open(RedirectLinks.DOCS);
          },
        },
        {
          title: "Blog",
          handleClick: () => {
            window.open(RedirectLinks.BLOG);
          },
        },
        {
          title: "Github",
          handleClick: () => {
            window.open(RedirectLinks.GITHUB);
          },
        },
        {
          title: "Changelog",
          handleClick: () => {
            window.open(RedirectLinks.CHANGELOG);
          },
        },
      ],
    },

    {
      title: NavbarItems.COMPANY,
      handleClick: () => {
        setSelectedNavItem(NavbarItems.COMPANY);
      },
      dropdownHeight: 64,
      dropwdownItem: [
        // {
        //   title: "Partner",
        //   handleClick: () => {
        //     window.open(RedirectLinks.PARTNER);
        //   },
        // },
        {
          title: "Contact Us",
          handleClick: () => {
            window.open(RedirectLinks.CONTACT_US);
          },
        },
        {
          title: "Media Kit",
          handleClick: () => {
            window.open(RedirectLinks.MAREKTINGKIT);
          },
        },
      ],
    },
  ];

  const [selectedNavItem, setSelectedNavItem] = useState<NavbarItems>(page);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<NavbarItems | null>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        // Timeout to handle the animation duration.
        setTimeout(() => setSelectedNavItem(page), 200);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [page]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav
      // data-aos="fade-down"
      // data-aos-once="true"
      // data-aos-duration="200"
      // data-aos-delay="0"
      className="z-50 sticky top-0 lg:w-full rounded-t-md"
    >
      <div
        className={
          "flex flex-col-reverse lg:flex-col items-center justify-center lg:w-full border-b-[0.5px] duration-500 rounded-t-md " +
          (isMobileNavOpen
            ? "bg-black border-black rounded-none"
            : "bg-white border-protocolNew-border")
        }
        ref={dropdownRef}
      >
        <div
          className="flex flex-row gap-x-1.5 lg:gap-x-5 font-sans items-center justify-between w-full z-50
        lg:rounded-b-none lg:rounded-t-md h-[62px]"
        >
          <div className="relative ml-6 md:ml-0 w-sides flex justify-center items-center shrink-0 grow-0">
            <div
              onClick={() => window.open("/", "_self")}
              className="cursor-pointer w-[100px] h-full flex justify-center items-center"
            >
              <Image
                src={SpheronLogo}
                alt="spheron"
                className={isMobileNavOpen ? "invert" : ""}
              />
            </div>
          </div>
          <div className="text-lg hidden md:flex w-content items-center justify-center gap-10">
            {navItems.map((item) => {
              const isSelected = selectedNavItem === item.title;
              return (
                <button
                  key={item.title}
                  className="flex flex-row px-1.5 pb-1.5 lg:pb-0 lg:px-0 gap-x-1.5 items-center hover:!text-protocolNew-base"
                  onClick={() => {
                    trackUserEvent(`Navbar Item ${item.title} Click`);
                    item.handleClick();
                    if (item.dropwdownItem.length > 0) {
                      if (selectedNavItem !== item.title) setIsOpen(true);
                      else toggleDropdown();
                    }
                  }}
                >
                  <span
                    className={`hidden lg:flex ${
                      isSelected ? "text-base" : "text-transparent"
                    }`}
                  >
                    &bull;
                  </span>
                  <span
                    className={`leading-3 font-normal hover:text-protocolNew-base
                    flex flex-row gap-1 items-center ${
                      isSelected
                        ? "text-protocolNew-base"
                        : "text-protocolNew-sub-heading"
                    }`}
                  >
                    {item.title}
                    {item.dropwdownItem?.length > 0 && <ArrowDownIcon />}
                  </span>
                </button>
              );
            })}
          </div>
          <div className="w-[18vw] hidden md:block grow-0 relative h-full">
            <PrimaryButton
              variant="light"
              className="rounded-tr-md"
              onClick={() => {
                trackUserEvent(`Navbar Button Click`);
                buttonOnClick();
              }}
            >
              {buttonContent}
            </PrimaryButton>
          </div>
          <div
            className="flex md:hidden flex-col gap-1 mr-6 cursor-pointer"
            onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
          >
            <span
              className={
                "inline-block w-5 h-0.5 origin-center duration-500 " +
                (isMobileNavOpen
                  ? "bg-white rotate-45 translate-y-1.5"
                  : "bg-black")
              }
            ></span>
            <span
              className={
                "inline-block w-5 h-0.5 origin-center duration-500 " +
                (isMobileNavOpen ? "bg-white opacity-0" : "bg-black")
              }
            ></span>
            <span
              className={
                "inline-block w-5 h-0.5 origin-center duration-500 " +
                (isMobileNavOpen
                  ? "bg-white -rotate-45 -translate-y-1.5"
                  : "bg-black")
              }
            ></span>
          </div>
          <Dot
            className={
              "bottom-0 right-6 lg:right-sides translate-x-[3.5px] translate-y-[3.5px] z-50 duration-500 " +
              (isMobileNavOpen ? "opacity-0" : "opacity-100")
            }
          />
          <Dot
            className={
              "bottom-0 left-6 lg:left-sides -translate-x-[3.5px] translate-y-[3.5px] z-50 duration-500 " +
              (isMobileNavOpen ? "opacity-0" : "opacity-100")
            }
          />
        </div>
        <div
          className={
            "bg-black p-8 px-4 flex flex-col gap-8 absolute w-full top-[62px] font-sans text-lg transition-opacity duration-500 " +
            (isMobileNavOpen ? "opacity-100" : "opacity-0 pointer-events-none")
          }
        >
          <div className="flex flex-col">
            {navItems.map((navItem) => {
              const isSelected = selectedNavItem === navItem.title;
              const isOpen = navItem.title === openDropdown;
              return (
                <>
                  <div
                    key={navItem.title}
                    className={`font-normal text-white mb-6
                    flex flex-row justify-between items-center cursor-pointer`}
                    onClick={() => {
                      trackUserEvent(`Navbar Item ${navItem.title} Click`);
                      setOpenDropdown(
                        openDropdown === navItem.title ? null : navItem.title
                      );
                      navItem.handleClick();
                    }}
                  >
                    <span className="flex gap-2 items-center">
                      <Dot
                        className={
                          "relative !w-1.5 !h-1.5 bg-white " +
                          (isSelected ? "opacity-100" : "opacity-0")
                        }
                      />
                      {navItem.title}
                    </span>
                    {navItem.dropwdownItem.length > 0 ? (
                      <Image alt="" src={ArrowDown} className="ml-auto" />
                    ) : null}
                  </div>

                  <div
                    className={
                      "flex flex-col gap-4 duration-400 transition-[height,opacity] "
                    }
                    style={{
                      height:
                        navItem.dropdownHeight && isOpen
                          ? `${navItem.dropdownHeight + 16}px`
                          : 0,
                    }}
                  >
                    {isOpen &&
                      navItem.dropwdownItem.map((item) => {
                        return (
                          <div
                            key={item.title}
                            className={`text-base ml-6 font-normal text-protocolNew-sub-heading
                         flex flex-row gap-2 items-center ${
                           item.hasOnClick ?? "hover:text-white cursor-pointer"
                         }`}
                            onClick={() => {
                              trackUserEvent(`Navbar Link ${item.title} Click`);
                              item.handleClick();
                            }}
                          >
                            {item.title}
                            {item.rightIcon}
                          </div>
                        );
                      })}
                  </div>
                </>
              );
            })}
          </div>
          <SecondaryButton
            variant="light"
            className="py-4"
            onClick={() => buttonOnClick()}
          >
            {buttonContent}
          </SecondaryButton>
        </div>
        <div
          className={`absolute w-content bottom-[34px] lg:bottom-[-68px] lg:top-[66px] z-[60]
        flex flex-col gap-y-2 font-roboto justify-center items-center
        rounded-md  py-[8px] px-[6px] h-max
        border-[0.5px] border-protocolNew-border bg-protocolNew-bg 
        transition-all duration-300 ease-in-out transform origin-top-right ${
          isOpen
            ? "scale-100 opacity-100"
            : "scale-95 opacity-0 pointer-events-none"
        }`}
        >
          {navItems
            .find((item) => item.title === selectedNavItem)
            ?.dropwdownItem.map((dropdownItem) => (
              <div
                key={dropdownItem.title}
                className={`text-sm font-normal text-protocolNew-light-sub-text
                  flex flex-row gap-2 items-center ${
                    dropdownItem.hasOnClick ??
                    "hover:text-protocolNew-base cursor-pointer"
                  }`}
                onClick={() => {
                  trackUserEvent(
                    `Navbar Dropdown Item ${dropdownItem.title} Click`
                  );
                  dropdownItem.handleClick();
                }}
              >
                {dropdownItem.title}
                {dropdownItem.rightIcon}
              </div>
            ))}
        </div>
        {/* <div
          className={`flex flex-col gap-y-2 font-roboto justify-center items-center w-full
        rounded-t-md lg:rounded-t-none lg:rounded-b-md pt-[8px] pb-[9px] px-[6px]
        border-[0.5px] border-protocolNew-border hover:bg-protocolNew-bg
        transition-all duration-300 ease-in-out transform origin-top-right ${
          !isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        >
          <div
            className="text-xs lg:text-sm font-normal text-protocolNew-base cursor-pointer"
            onClick={() => window.open(RedirectLinks.CONSOLE_APP)}
          >
            Spheron Console app is live, check it out! 🙌
          </div>
        </div> */}
      </div>
    </nav>
  );
};

export default NavBar;
