const ArrowDownIcon = () => {
  return (
    <svg
      viewBox="0 0 17 17"
      className="w-4 h-4 fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.16552 6.79511C5.37078 6.61038 5.68692 6.62701 5.87165 6.83227L8.37612 9.61501C8.44233 9.68858 8.55768 9.68858 8.62389 9.61501L11.1284 6.83227C11.3131 6.62701 11.6292 6.61038 11.8345 6.79511C12.0397 6.97983 12.0564 7.29598 11.8717 7.50123L9.36718 10.284C8.90373 10.7989 8.09628 10.7989 7.63283 10.284L5.12836 7.50123C4.94363 7.29598 4.96027 6.97983 5.16552 6.79511Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowDownIcon;
