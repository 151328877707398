import React, { ComponentProps } from "react";

interface PrimaryButtonProps extends ComponentProps<"button"> {
  variant: "dark" | "light";
}

const getVariantClassNames = (variant: PrimaryButtonProps["variant"]) => {
  switch (variant) {
    case "dark":
      return "bg-black text-white hover:bg-white hover:text-black";
    case "light":
      return "bg-white text-protocolNew-subheading hover:bg-black hover:text-white";
  }
};

const PrimaryButton = ({
  className,
  variant,
  children,
  ...props
}: PrimaryButtonProps) => {
  return (
    <button
      className={`w-full h-full font-mono overflow-hidden group duration-300 ease-out text-sm ${getVariantClassNames(
        variant
      )} ${className}`}
      {...props}
    >
      <div className="leading-5 overflow-hidden">
        <div className="max-h-5 duration-300 ease-out group-hover:-translate-y-full">
          <div>{children}</div>
          <div>{children}</div>
        </div>
      </div>
    </button>
  );
};

export default PrimaryButton;
