type VerticalPosition = "top" | "bottom";
type HorizontalPosition = "left" | "right";
type Position = `${VerticalPosition}-${HorizontalPosition}`;

interface DotProps {
  className?: string;
}

const Dot = ({ className }: DotProps) => {
  return (
    <span
      className={`border border-white w-[8px] h-[8px] absolute bg-[#81838D] inline-block rounded-full ${className}`}
    ></span>
  );
};

export default Dot;
